
.image-news .img-4{
    width:30rem;
    margin-top:-10vh;
}
.image-news .img-3{
    height: 5rem;
    padding-left:15vw;
}

.image-news .img-2{
    width:20rem;
    height: 5rem;
    padding-left:15vw;
}
.image-news .img-1{
    width:8rem;
    height: 5rem;
}
.image-news{
    display: flex;
    justify-content: center;
    max-width: 80%;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
}
.br4 {
    background-position: -366px;
    width: 167px;
    min-width: 167px;
}
.br3{
    background-position: -287px;
width: 55px;
min-width: 55px;
}
.br2{
    background-position: -187px;
    width: 77px;
    min-width: 77px;
}
.br1{
    background-position: 0;
width: 165px;
min-width: 165px;
}
.br1,.br2,.br3,.br4{
    cursor: pointer;
    display: block;
    height: 32px;
    background-repeat: no-repeat;
    /* background-image: url('../social.svg'); */
    background-size: cover;
    margin-top: 45px;
    margin-left:auto;
    margin-right: auto;
    
}
.image-social{
    display: flex;
    justify-content: center;
    max-width: 80%;
    margin: 0 auto 75px auto;
    flex-direction: row;
    text-align: center;
}
#refLink{
    word-break: break-word;;
}
#WEB3_CONNECT_MODAL_ID{
    
}
.bg-img{
    background-repeat: repeat-x;
}
h1,
h2,
h3,
h4,
input {
    
    color: #182035;
    font-weight: bold;
}

p,
a,
span,
label,
h5,
h6 {
    
    text-decoration: none;
    color: #182035;
    font-weight: normal;
}

a:focus,
a:active {
    outline: none;
}

.body {
    /* background-image: url("../images/main_bg.html"); */
    background-repeat: no-repeat;
    background-color: #f4fdf9;
    background-size: cover;
    max-width: 100%;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    font-family: "Kanit-Regular";
}

/*--------------header---------------*/

.header {
    background: rgb(244 253 249);
    padding: 16px 0 19px;
    min-height: 46px;
    position: static;
    top: 0;
}

.container-info {
    max-width: 1170px;
    width: 100%;
    margin: 0 auto;
}
video{
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
}
.container-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.header .container-info,
.header {
    display: flex;
    align-items: center;
}

.btn-contact {
    border: 1px solid #20c1b2;
    box-sizing: border-box;
    border-radius: 30px;
    width: 130px;
    height: 45px;
    padding: 12px 30px;
    background: transparent;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.btn-contact:hover {
    background: rgba(32, 193, 184, 0.2);
    transition: 0.3s;
}

.btn-contact span {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: normal;
    color: #000;
    text-align: center;
    display: block;
}

.connect {
    margin-right: 20px;
}

.connect span {
    position: relative;
    padding-right: 16px;
}

.connect span:before {
    content: "";
    position: absolute;
    right: 0;
    width: 8px;
    height: 8px;
    /* background: url("../images/plus.svg") no-repeat; */
    background-size: cover;
    top: 50%;
    transform: translateY(-50%);
}

.item-link {
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    border-radius: 100%;
    width: 45px;
    height: 45px;
    display: block;
}

.item-link:hover {
    border: 1px solid rgba(255, 255, 255, 0.7);
    opacity: 0.7;
}

.contact-list {
    display: flex;
}

.item-list {
    margin-right: 4px;
}

.item-list:last-child {
    margin-right: 0;
}

.discord .item-link {
    /* background: url("../images/discord.svg") no-repeat; */
    background-size: 16px 16px;
    background-position: center;
}

.twitter .item-link {
    /* background: url("../images/twitter.svg") no-repeat; */
    background-size: 16px 13px;
    background-position: center;
}

.telegram .item-link {
    /* background: url("../images/telegram.svg") no-repeat; */
    background-size: 16px 13px;
    background-position: center;
}

.header-contacts {
    display: flex;
    align-items: center;
}

.container-btn-contacts {
    /* margin: 0 57px; */
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-end;
}

/*----------first-screen------------*/

.main-title,
.main-title span {
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: normal;
    color: #000;
    max-width: 505px;
}

.main-title span {
    color: #20c1b2;
}

.sub-title-main,
.sub-title-main span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: normal;
    color: #ffffff;
}

.sub-title-main span {
    font-weight: bold;
}

.sub-title-main {
    margin: 20px 0 49px;
}

.pink-btn {
    /* background: linear-gradient(270deg, #20c1b2 0%, #078093 96.85%); */
    /*background-color: #40bb84;*/
    background-color: #000000;
    box-shadow: 0px 5px 25px rgba(32, 193, 184, 0.6), inset 0px -1px 0px rgba(0, 0, 0, 0.32);
    border-radius: 30px;
    width: 270px;
    height: 55px;
    border: none;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: normal;
    color: #ffffff;
}

.pink-btn:hover {
    /*
    background-color: #20bf55;
    background-image: linear-gradient(315deg, #20bf55 0%, #008f4f 74%);
    */
    background-color: #dbb72c;
    background-image: linear-gradient(315deg, #e8c951 0%, #ccd046 74%);
}

.screen-block {
    min-height: 552px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 80px;
}

.screen-block > div,
.right-screen > div {
    padding-right: 15px;
    padding-left: 15px;
    flex-grow: 1;
    flex-shrink: 1;
}

.block-result {
    background: #ffffff;
    border-radius: 6px;
    padding: 16px 25px;
    width: fit-content;
    /* display: flex; */
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    min-width: 220px;
}

.block-result:last-child {
    margin-bottom: 0;
}

.result {
    margin-right: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: normal;
    text-align: left;
}

.sub-text-result {
    font-size: 14px;
    width: fit-content;
    max-width: 95px;
}

.pink-text {
    font-weight: bold;
    color: #20c1b2;
}

.bold-text {
    font-weight: bold;
}

.container-info-screen {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.container-first-screen {
    max-width: 100%;
    width: 100%;
}

.first-screen {
    padding-bottom: 82px;
}

.container-result {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

/*--------------calculate block--------*/

.header-section {
    margin-bottom: 62px;
    text-align: center;
}

.title-header {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: normal;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #40bb84;
}

.container-calculate-info {
    padding: 90px 30px 100px 50px;
    background: rgb(128, 186, 195);
    border-radius: 30px;
    min-height: 200px;
    max-width: 836px;
    margin: 0 auto;
}

.container-slider {
    position: relative;
    max-width: 600px;
    width: 90%;
}

.datalist {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
}

.ui-slider .slider-range-inverse {
    width: 100%;
    height: 13px;
    background: rgba(25, 32, 54, 0.1);
    border-radius: 50px;
    position: relative;
}

.ui-slider {
    /* background: linear-gradient(266.03deg, #20c1b2 24.24%, #078093 68.14%); */
    background-color: #40bb84;
    border-radius: 30px;
    height: 12px;
    position: absolute;
    left: 0;
    top: -22px;
    bottom: -20px;
    right: 0;
    border: 1px solid #e8e9eb;
}

.ui-state-default,
.ui-widget-content .ui-state-default {
    position: absolute;
    right: -6px;
    top: 50%;
    transform: translateY(-50%);
    border: 6px solid #ffffff;
    box-sizing: border-box;
    border-radius: 30px;
    width: 22px;
    height: 22px;
    cursor: pointer;
}

.ui-slider .ui-slider-handle:before {
    content: "";
    position: absolute;
    left: -6px;
    right: -6px;
    top: -6px;
    bottom: -6px;
    width: 21px;
    height: 21px;
    border: 1px solid #bbbbbb;
    border-radius: 100%;
}

.mark {
    position: absolute;
    background: #ffffff;
    width: 50px;
    height: 30px;
    border-radius: 10px;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: #000000;
    top: -45px;
    left: 50%;
    transform: translateX(-50%);
}

.mark:after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -13px;
    border: 8px solid transparent;
    border-top: 8px solid white;
}

.datalist li {
    
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #182035;
    opacity: 0.5;
}

.title-deposit {
    margin-bottom: 45px;
    font-weight: bold;
    font-size: 16px;
}

.ui-slider .slider-range-inverse {
    background: #e8e9eb;
    position: absolute;
    right: 0;
}

.ui-slider .ui-slider-range {
    background: transparent;
}
.deposit-block{
        width: 202px;
}
.amount-field {
    background: #f2f3f4;
    border: 1px solid #20c1b2;
    box-sizing: border-box;
    border-radius: 10px;
width: 100%;
    height: 50px;
    /* padding: 14px 23px 14px 20px; */
    position: relative;
    -webkit-user-select: text;
    cursor: auto;
    -webkit-appearance: textfield;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    
    display: flex;
    align-items: center;
}
.amount-field input{
    padding: 14px 55px 14px 20px;
    width: 100%;

}
.amount-field-button {
    /* content: "Max"; */
    z-index: 5;
    position: absolute;
    
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: normal;
    color: #20c1b2;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.amount-title {
    margin-bottom: 28px;
}

.container-counter {
    display: flex;
    justify-content: space-between;
    padding-bottom: 35px;
    position: relative;
}

.container-counter::after {
    content: "";
    position: absolute;
    height: 1px;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 100%;
    background: linear-gradient(266.61deg, #ffffff -1.47%, #20c1b2 46.7%, #ffffff 99.87%);
}

.deposit {
    width: 80%;
}

.amount-input {
    /* max-width: 116px; */
    background: transparent;
    border: none;
}

.list-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.item-info {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.footer-counter {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
}

.invest-btn {
    margin: 0 auto;
    margin-top: 46px;
    width: 300px;
}

.title-info {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: normal;
    margin-right: 8px;
}

.item-info p {
    font-weight: bold;
    font-size: 20px;
}

.item-info p.pink-text span {
    color: #000;
    font-weight: bold;
}

/*------------------dashboard-section------------*/

.dashboard-section {
    margin-top: 111px;
    padding-bottom: 98px;
}

.container-dashboard-info {
    background: rgb(128, 186, 195);
    border-radius: 30px;
    min-height: 200px;
    max-width: 890px;
    margin: 0 auto;
    padding: 42px 43px 39px 38px;
}

.item-dashboard > div {
    background: #ffffff;
    border-radius: 9px;
    padding: 14px 21px 18px;
    width: 158px;
    height: 53px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.item-dashboard {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    background-image: linear-gradient(266.03deg, #20c1b2 24.24%, #078093 68.14%);
    border-image: -moz-linear-gradient(266.03deg, #20c1b2 24.24%, #078093 68.14%);
    background: -webkit-linear-gradient(left, #20c1b2 0%, #078093 100%);
    box-sizing: border-box;
    padding: 1px;
    width: fit-content;
    width: -moz-fit-content;
}

.list-dashboard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 63px;
    position: relative;
}

.title-block-item {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    margin-bottom: 5px;
    line-height: 15px;
}

.info-item span {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
}

.list-dashboard::after {
    content: "";
    position: absolute;
    height: 1px;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 100%;
    background: linear-gradient(266.61deg, #ffffff -1.47%, #20c1b2 46.7%, #ffffff 99.87%);
}

.withdraw-btn {
    width: 210px;
    height: 45px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -23px;
}

.container-footer-dashboard {
    padding-top: 8px;
}

.title-footer-dashboard {
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    margin-bottom: 37px;
    position: relative;
    line-height: 24px;
}

.title-footer-dashboard span {
    font-weight: bold;
    margin-left: 5px;
}

.list-footer {
    display: flex;
    justify-content: space-between;
}

.item-footer {
    background: #f2f3f4;
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: fit-content;
}

.title-level {
    color: #000000;
    opacity: 0.7;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 4px;
}

.second-level {
    font-size: 14px;
}

.three-level {
    opacity: 0.8;
    font-size: 16px;
}

.item-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px 10px 8px;
    max-width: 240px;
    width: 100%;
    /* height: 42px; */
    justify-content: space-between;
}

.fourth-level {
    opacity: 0.9;
    font-size: 18px;
}

.fifth-level {
    opacity: 1;
    font-size: 20px;
}

.info-level {
    font-weight: normal;
    font-size: 10px;
}

/*------------------footer--------------*/

.container-logo-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.container-logo-footer span {
    font-weight: normal;
    font-size: 12px;
    color: #000;
    line-height: normal;
}

.container-logo-footer .logo-span {
    font-weight: bold;
}

.footer {
    min-height: 46px;
    position: relative;
    padding: 23px 0 31px;
}

.footer:before {
    content: "";
    position: absolute;
    height: 1px;
    left: 0;
    right: 0;
    top: 0;
    border-radius: 100%;
    background: linear-gradient(266.61deg, #010727 -1.47%, #20c1b2 46.7%, #04010b 100%);
}

.footer-btn-contact {
    width: 192px;
    height: 45px;
    font-size: 14px;
}

.container-footer-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container-footer-btn {
    display: flex;
    align-items: center;
    width: 60%;
    justify-content: space-between;
}

.footer .contact-list {
    margin-left: 30px;
}

.container-list-contact {
    display: flex;
    align-items: center;
}

/*-----------popup windows-------*/

.main-container-center {
    background: #ffffff;
    border-radius: 30px;
    height: auto;
    min-height: 500px;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    max-width: 970px;
    width: 100%;
    margin: 0 auto;
}

.faq-block {
    padding: 17px 25px 36px 69px;
}

.wrapper-dark {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 10000;
    display: none;
    transition: 0.3s;
}
.container-faq {
    display: none;
    transition: 0.3s;
}

.active-faq {
    display: block;
    transition: 0.3s;
}

.title-window {
    font-weight: bold;
    font-size: 36px;
    line-height: normal;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #000000;
    text-align: center;
}

.window-header {
    margin: 0 auto;
}

.title-faq-item {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: normal;
    /* text-transform: uppercase; */
    color: #000000;
}

.item-faq {
    padding: 30px 0 30px 0;
    position: relative;
}

.header-item-faq {
    position: relative;
    padding-right: 43px;
}

.container-info-faq {
    padding-top: 30px;
    display: none;
}

.container-info-faq p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: normal;
    color: #303030;
    margin-bottom: 15px;
    padding-right: 50px;
}

.container-info-faq p:last-child {
    margin-bottom: 0;
}

.item-faq::after {
    content: "";
    position: absolute;
    height: 1px;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 100%;
    background: linear-gradient(266.61deg, #ffffff -1.47%, #20c1b2 100%, #ffffff 100%);
}

.item-faq:last-child::after {
    display: none;
}

.close-faq-item {
    box-sizing: border-box;
    border-radius: 30px;
    width: 43px;
    height: 43px;
    /* background: url("../images/btn_arrow.svg") no-repeat; */
    border: none;
    background-size: contain;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s;
}

.active-btn-faq {
    transform: translateY(-50%) rotate(180deg);
    transition: 0.3s;
}

.close-window {
    position: absolute;
    width: 43px;
    height: 43px;
    /* background: url("../images/X.svg") no-repeat; */
    background-size: contain;
    box-sizing: border-box;
    border-radius: 100%;
    border: none;
    top: -50px;
    right: -50px;
}

.close-window:hover {
    opacity: 0.5;
}

.container-connect {
    background: #ffffff;
    border-radius: 30px;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    min-height: 307px;
    max-width: 570px;
    width: 100%;
    margin: 0 auto;
    display: none;
    transition: 0.3s;
}

.active-connect {
    display: block;
    transition: 0.3s;
}


.container-window {
    padding: 20px 20px 58px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.item-connect {
    padding: 17px 25px;
    border-radius: 20px;
    margin-bottom: 20px;
    position: relative;
    cursor: pointer;
    background: linear-gradient(270deg, #c12088 0%, #20C187 96.85%);
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.32);
    max-width: 172px;
    width: 100%;
    padding-right: 42px;
}

.list-connect {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 50px 0 0;
}

.item-connect p {
    font-weight: bold;
    font-size: 14px;
    line-height: normal;
    color: #ffffff;
}
.pink-btn a {
    font-weight: bold;
    font-size: 14px;
    line-height: normal;
    color: #ffffff;
}

.info-window {
    position: relative;
    padding-left: 24px;
    font-size: 10px;
}

.info-window:before {
    content: "";
    position: absolute;
    left: 0;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    /* background: url("../images/asc_img.svg") no-repeat; */
    top: 50%;
    transform: translateY(-50%);
}

.metamask:before,
.binance:before {
    content: "";
    position: absolute;
    right: 8px;
    width: 34px;
    height: 34px;
    border-radius: 100%;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: center;
    top: 50%;
    transform: translateY(-50%);
}

.binance:before {
  
    background-repeat: no-repeat;
    background-position: center;
}

/*--------------warn messages-----------*/

.warn-btn {
    color: #000003;
    font-size: 12px;
}

.warn-container {
    background: #ffffff;
    padding: 14px 0 7px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    min-height: 59px;
    z-index: 10;
}

.error-warn {
    background: #20c1b2;
}

.title-warn {
    font-weight: bold;
    font-size: 18px;
    line-height: normal;
    text-transform: uppercase;
    color: #000000;
}

.warn-container .container-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.info-warn p {
    max-width: 972px;
    width: 100%;
    font-size: 12px;
    color: #303030;
}

.info-warn {
    max-width: 80%;
}

.error-warn .title-warn,
.error-warn .info-warn p,
.error-warn .warn-btn {
    color: #ffffff;
}

.error-warn .warn-btn {
    border: 1px solid #ffffff;
}

.error-warn .warn-btn:hover {
    background: rgba(255, 255, 255, 0.3);
}

.active-bg {
    display: block;
}

.mobile-open,
.mobile-fade,
.warn-container {
    display: none;
}

#warn.hidden {
    display: none;
}
#warn.fade-in {
    display: flex;
    transition: 0.3s;
}

button {
    position: relative;
    overflow: hidden;
}
button .spinner {
    position: absolute;
    top: 0;
    left: 50%;
    width: 32px;
    height: 100%;
    /* background-image: url("../images/spinner.svg"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    transform: translate(-50%, 0%);
    animation: spinner 1.5s linear 0s infinite;
    /* filter: brightness(50) grayscale(10) ; */
    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
    /* animation: openedMobileMenu 0.4s linear 0s 1 reverse backwards; */
}
@keyframes spinner {
    from {
        transform: rotate(0) translate(-50%, 0%) scaleX(0.1);
    }
    25% {
        filter: none;
    }
    50% {
        transform: rotate(0) translate(-50%, 0%) scaleX(1);
    }
    75% {
        filter: brightness(50) grayscale(10);
    }
    to {
        transform: rotate(0) translate(-50%, 0%) scaleX(0.1);
    }
}

.btn-copy{
    width: 24px;
    height: 24px;
    position: relative;
}
.btn-copy::after{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-position: top center;
    filter: grayscale(1) opacity(.3);
    background-repeat: no-repeat;
}
.btn-copy:hover::after{
    filter: none;
    transition: .2s;
}
.btn-copy:active::after{
    filter: brightness(.9);
}
span.title-copy{
    position: absolute;
    color: #20c1b2;
    text-align: center;
    width: 100%;
    left: 0;
    bottom: 0;
    transform: translate(0, 80%);
    font-weight: 500;
}

.mainsection-content
{
  padding: 50px 45px;
}

.mainsection-content h1
{
  font-size: 5rem;
}
.header-holder
{
  border-bottom: 2px solid #dee2e6 !important;
  padding: 30px 0;
}

@media (min-width: 992px) and (max-width: 1200px)
{
  .mainsection-content h1 {
    font-size: 45px;
    font-weight: 400;
    line-height: 62px;
}
}
@media (min-width: 0px) and (max-width: 991px)
{
  .mainsection-content h1 {
    font-size: 36px;
    font-weight: 400;
    line-height: 50px;
}
}

.logo2
{
    padding: 36px 0 0 0;
  width: 100%;
}
.align-center
{
  align-self: center;
}
.content-section
{
  width: 60%;
}
.content-img
{
    width: 40%;
}

.mainsection-content
{
  display: flex;
  flex-wrap: wrap;
}
.head-border
{
    border-bottom: 2px solid #dee2e6 !important;
    padding: 26px 0;
}

.right-main-section, .right-main-section1 {
    overflow: scroll;
    border-radius: 16px;
    margin-bottom: 2.5rem;
}

.right-main-section {
    overflow: scroll;
    background: #80bac3;;
    box-shadow: 0 0 8px rgb(0 0 0 / 25%);
    padding: 1rem;
    /* min-height: 650px; */
    height: 545px;
}
.last-deposit-head {
    font-size: 1.43rem;
    color: #fff;
    margin: 1rem 0;
}

.deposit-holder::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    background-color: #545452;
}
.deposit-holder::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    border-radius: 10px;
    background-color: #f5f5f5;
}
.deposit-holder::-webkit-scrollbar {
    border-radius: 50px;
    width: 4px;
    background-color: #f5f5f5;
}
.deposit-item {
    background: #f4fdf9;
    box-shadow: 0 0 8px rgb(0 0 0 / 25%);
    border-radius: 16px;
    padding: 1rem;
}
.deposit-item-top .tx-hash {
    font-size: 14px;
    color: rgb(64 187 132);
}
.deposit-item-top .tx-date {
    font-size: 13px;
    color: #000;
}
.deposit-item-bottom .wallet-address {
    font-size: 14px;
    color: #000;
    margin: 0;
}
.deposit-item-bottom .amount {
    font-size: 12px;
    color: #000;
    margin: 0;
}
.deposit-item-bottom .plan {
    font-size: 12px;
    line-height: 24px;
    color: #000;
    margin: 0;
}
.deposit-item-bottom .plan .plan-number {
    color: rgb(128, 186, 195);
    font-weight: 700;
}
.align-items-center {
    align-items: center !important;
}

.justify-content-between {
    justify-content: space-between !important;
}
.gap-2 {
    gap: 0.5rem !important;
}
.d-flex {
    display: flex !important;
}
.mb-1 {
    margin-bottom: 0.25rem !important;
}


.gap-2 {
    gap: 0.5rem !important;
}
.container-deposit-info
{
    
    /* background: rgb(128, 186, 195) */
    border-radius: 30px;
    min-height: 200px;
    max-width: 998px;
    margin: 0 auto;
    /* padding: 42px 43px 39px 38px; */
  
}

.container-deposit-info .screen-block
{
  padding: 0;
  width: auto !important;
}
.dapp-img {
    height: 45px !important;
    width: 87px !important;
}
.footer-bottom-img.social {
    height: 35px;
    width: unset;
}
.footer-audit-box {
    /* background: #fff; */
    border-radius: 15px;
    width: 60%;
    margin: auto;
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: 42px;
}

.footer-bottom-img {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    cursor: pointer;
}

.justify-content-evenly {
    justify-content: space-evenly!important;
}

.flex-wrap {
    flex-wrap: wrap!important;
}
input[type='range'] {
    display: block;
  }
  
  input[type='range']:focus {
    outline: none;
  }
  
  #inputDiv {
    width: 500px;
    margin: 150px auto;
    position: relative;
  }
  
  #etiqueta {
    width: 30px;
    height: 26px;
    position: absolute;
    z-index: 9;
    background-color: HotPink;
    color: white;
    font-size: 12px;
    text-align: center;
    padding-top: 7px;
    top: -45px;
  }
  
  #etiqueta:after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 8;
    background-color: HotPink;
    position: relative;
    left: 9px;
    /*top:-1px;*/
  }
  /* 2 */
  
  input[type='range'],
  input[type='range']::-webkit-slider-runnable-track,
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
  }
  /* 2.1 THUMB */
  
  input[type=range]::-webkit-slider-thumb {
    background-color: #fff;
    width: 20px;
    height: 20px;
    border: 3px solid #20C187;
    border-radius: 50%;
    margin-top: -9px;
    -webkit-appearance: none;
  }
 
  input[type=range]::-webkit-slider-runnable-track {
    background-color: #fff;
    height: 3px;
  }
  
  input[type=range]:focus::-webkit-slider-runnable-track {
    outline: none;
  }
  
  input[type=range]::-moz-range-track {
    background-color: #555;
    height: 3px;
  }
  
  input[type=range]::-ms-track {
    background-color: #555;
    height: 3px;
  }
  
  input[type=range]::-ms-fill-lower {
    background-color: #008f4f
  }
  
  input[type=range]::-ms-fill-upper {
    background-color:#008f4f ;
  }
  
  .form-control {
    display: block;
    width: 100%;
    padding: 2px 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    /* background-color: #f4fdf9; */
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-control:focus
{
    color: #80bac3;;
    background-color: #f4fdf9;
    border-color: initial;
    outline: 0;
    box-shadow: 0 0 0 0rem rgb(13 110 253 / 25%);
}

.calculate-block-container
{
    padding: 30px 0 80px 0;
}
@media (min-width: 0px) and (max-width: 549px)
{
    .invest-btn
{
  width: 100%;
}
.container-calculate-info
{
    padding: 35px;
}
.deposit-block
{
    width: 100%;
}
.container-slider
{
    width: 100%;
}
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;
    /* background: #d3d3d3; */
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }
  
  /* .slider:hover {
    opacity: 1;
  } */
  
  input[type = range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
      width: 22px;
    height: 22px;
    border-radius: 50%;
    background: #12161c;
    cursor: pointer;
  }
  
  input[type = range]::-moz-range-thumb {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: #12161c;
    cursor: pointer;
  }
  .slider::-ms-fill-lower { 
    background: #000;
  }
  @media (min-width: 655px) and (max-width: 767px)
  {
    .container-counter
    {
        display: block;
    }
  }


  /*Chrome*/

  input[type="range"] { 
    margin: auto;
    -webkit-appearance: none;
    position: relative;
    overflow: hidden;
    height: 25px;
    width: 100%;
    cursor: pointer;
    border-radius: 8px;
  }
  
  ::-webkit-slider-runnable-track {
    background: #fff;
  }
  
  /*
  * 1. Set to 0 width and remove border for a slider without a thumb
  */
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px; /* 1 */
    height: 40px;
    background: #fff;
    box-shadow: -100vw 0 0 100vw #40bb84;
    border-radius: 50%;
    transition: 2s;
   
  }
  
  ::-moz-range-track {
    height: 40px;
    background: #fff;
  }
  
  ::-moz-range-thumb {
    background: #fff;
    height: 40px;
    width: 20px;
    border-radius: 0 !important;
    box-shadow: -100vw 0 0 100vw #40bb84;
    box-sizing: border-box;
    border-radius: 50%;
    transition: 2s;
  }
  
  ::-ms-fill-lower { 
    background: #2ABA4C;
    
  }
  
  ::-ms-thumb { 
    background: #fff;
    height: 40px;
    width: 20px;
    box-sizing: border-box;
    border-radius: 50%;
  }
  
  ::-ms-ticks-after { 
    display: none; 
  }
  
  ::-ms-ticks-before { 
    display: none; 
  }
  
  ::-ms-track { 
    background: #fff;
    color: transparent;
    border: none;
    border-radius: 50%;
  }
  
  ::-ms-tooltip { 
    display: none;
  }

  .selectlayoutadjustment
  {
    width: 50%;
  }

  .popupbackground
  {
    background-color: #f0d87b !important;
  }
  .ml-15
  {
    margin-left: 15px;
  }




 
/* For Tablet View */
@media screen and (min-device-width: 768px)
and (max-device-width: 1024px) {
  .autoresizeaffiliate {
    -moz-transform: scale(0.8);
    -webkit-transform: scale(0.8);
    -o-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
  }
}
 
/* For Mobile Portrait View */
@media screen and (max-device-width: 480px)
and (orientation: portrait) {
  .autoresizeaffiliate {
    -moz-transform: scale(0.7);
    -webkit-transform: scale(0.7);
    -o-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(0.7);
  }
}
 
/* For Mobile Landscape View */
@media screen and (max-device-width: 640px)
and (orientation: landscape) {
  .autoresizeaffiliate {
    -moz-transform: scale(0.7);
    -webkit-transform: scale(0.7);
    -o-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(0.7);
  }
}
 
/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
  .autoresizeaffiliate {
    -moz-transform: scale(0.6);
    -webkit-transform: scale(0.6);
    -o-transform: scale(0.6);
    -ms-transform: scale(0.6);
    transform: scale(0.6);
  }
}
 
/* For iPhone 4 Portrait or Landscape View */
@media screen and (min-device-width: 320px)
and (-webkit-min-device-pixel-ratio: 2) {
  .autoresizeaffiliate {
    -moz-transform: scale(0.5);
    -webkit-transform: scale(0.5);
    -o-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
  }
}
 
/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px) and (device-width: 320px)
and (-webkit-min-device-pixel-ratio: 2) {
  .autoresizeaffiliate {
    -moz-transform: scale(0.6);
    -webkit-transform: scale(0.6);
    -o-transform: scale(0.6);
    -ms-transform: scale(0.6);
    transform: scale(0.6);
  }
}
 
/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) and (min-device-width: 375px)
and (-webkit-min-device-pixel-ratio: 3) {
  .autoresizeaffiliate {
    -moz-transform: scale(0.6);
    -webkit-transform: scale(0.6);
    -o-transform: scale(0.6);
    -ms-transform: scale(0.6);
    transform: scale(0.6);
  }
}

/* For Desktop View */
@media screen and (min-width: 1024px) {
  .autoresizeaffiliate {
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}