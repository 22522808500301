@font-face {
  font-family: "Kanit-Regular";
  src: url("./assets/fonts/Kanit-Regular.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "LuckiestGuy-Regular";
  src: url("./assets/fonts/LuckiestGuy-Regular.ttf") format("truetype");
  font-weight: normal;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: "Kanit-Regular";
  background-color: #f9e2c4;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
a {
  text-decoration: none;
  color: unset;
}
a:hover {
  color: unset;
}
.cursor-pointer {
  cursor: pointer;
}

/* Header Section */
.header-section {
  width: 100%;
  height: 3.75rem;
  background: #f9e2c4;
}
.logo-text {
  font-family: "LuckiestGuy-Regular";
  font-size: 1.68rem;
  color: rgb(128 195 165);
}
.logo-text > span {
  color: #fff;
}
.connect-button {
  color: #FFFFFF;
  background: #000000;
  border-radius: 16px;
  padding: 0.4rem 1.5rem;
  outline: none;
  border: none;
}
@media screen and (max-width: 415px) {
  .header-section {
    height: 6rem;
  }
  .right-header-holder {
    justify-content: end;
  }
  .account-address {
    margin-right: 14px;
  }
}

/* Main content Section */

.main-content-section {
  width: 100%;
  min-height: 1110px;
}
.top-info-bnbyields > h1 {
  font-size: 2rem;
  color: #1e2329;
  margin-bottom: 1.5rem;
}
.top-info-bnbyields > p {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: #1e2329;
}

.total-info-box {
  background: #12161c;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 1rem 2rem;
  min-height: 150px;
}
.total-info-title {
  font-size: 1.43rem;
  color: #ffffff;
}
.total-info-value {
  font-size: 1.93rem;
  color: rgb(128 195 165);
}
.total-info-dollor {
  font-size: 0.93rem;
  color: #afafaf;
}
.main-btn {
  background: #000000;
  border-radius: 16px;
  border: none;
  font-size: 1rem;
  color: #ffffff;
  padding: 1rem 0rem;
}
.account-address {
  color: #000;
}
.main-button-holder {
  margin: 2.5rem 0rem 2.5rem 0rem;
}
.right-main-section {
  background: #12161c;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 1rem;
  margin-bottom: 2.5rem;
}
.right-main-section1 {
  border-radius: 16px;
  margin-bottom: 2.5rem;
}
.farm-middle{
  gap: 3rem;
}
.last-deposit-head {
  font-size: 1.43rem;
  color: #ffffff;
  margin: 1rem 0rem;
}
.deposit-holder {
  /* background: #0a0c0f;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25); */
  border-radius: 16px;
  padding: 1rem;
 
  overflow-y: scroll;
}
.deposit-holder::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.deposit-holder::-webkit-scrollbar {
  border-radius: 50px;
  width: 4px;
  background-color: #f5f5f5;
}

.deposit-holder::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #545452;
}

.deposit-item {
  background: #12161c;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 1rem;
}
.deposit-item-top .tx-hash {
  font-size: 1.12rem;
  color: rgb(128 195 165);
}
.deposit-item-top .tx-date {
  font-size: 0.87rem;
  color: #afafaf;
}
.deposit-item-bottom .wallet-address {
  font-size: 1.12rem;
  color: #afafaf;
}
.deposit-item-bottom .amount {
  font-size: 0.87rem;
  color: #afafaf;
}
.deposit-item-bottom .amount .deposit-value {
  color: rgb(128 195 165);
  font-weight: bold;
}

.deposit-item-bottom .plan {
  font-size: 0.87rem;
  line-height: 24px;
  color: #afafaf;
}
.deposit-item-bottom .plan .plan-number {
  color: rgb(128 195 165);
  font-weight: bold;
}

@media screen and (max-width: 1200px) {
  .left-main-section {
    width: 70%;
  }
}
@media screen and (max-width: 990px) {
  .left-main-section {
    width: 70%;
  }
  .right-main-section {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .deposit-item-top {
    flex-wrap: wrap;
    justify-content: center !important;
    text-align: center;
  }
  .deposit-item-bottom {
    flex-wrap: wrap;
    justify-content: center !important;
    text-align: center;
  }
}
@media screen and (max-width: 575px) {
  .left-main-section {
    width: 100%;
  }
}
/* Stake Section */
.stake-section {
  width: 100%;
  /* min-height: 900px; */
}
.left-stake-section {
  background: #12161c;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.45);
  border-radius: 16px;
  padding: 1rem;
}
.stake-head {
  font-weight: 600;
  font-size: 1.93rem;
  color: rgb(128 195 165);
}
.stake-head-title {
  font-size: 0.87rem;
  color: rgb(128 195 165);
  width: 100px;
  text-align: center;
}
.stake-text {
  font-size: 1rem;
  color: #ffffff;
  width: 100px;
  text-align: center;
}
.stake-arrow-holder {
  width: 100px;
  text-align: left !important;
}
.stake-item {
  border-top: 2px solid;
  padding: 2rem 0rem;
}
.stake-arrow {
  transition: all 0.3s;
  border: solid #fff;
  border-width: 0 1px 1px 0;
  float: left;
  color: #fff;
  margin-top: 6px;
  padding: 4px;
  transform: rotate(45deg);
  margin-left: 8px;
}
.stake-input {
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0.5rem 0rem;
  width: auto;
  color: #fff;
}
.stake-button {
  background: rgb(128 195 165);
  position: absolute;
  border: none;
  border-radius: 16px;
  height: 100%;
  padding: 0.5rem 1.8rem;
  top: 0;
  right: 0;
}
.stake-control-holder {
  position: relative;
  border-radius: 16px;
  border: 1px solid rgb(128 195 165);
  width: 250px;
  padding: 0.2rem 0.5rem;
}

.farm-information-box {
  background: #80bac3;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 1rem;
}
.farm-head {
  font-weight: 600;
  font-size: 1.93rem;
  color: #1e2329;
  margin-bottom: 2rem;
}
.farm-item-title {
  font-size: 0.87rem;
  color: #1e2329;
}
.farm-item{
  gap: 5.5rem;
}
.farm-middle-value {
  font-size: 1.18rem;
  color: #1e2329;
}
.farm-dollor {
  font-size: 0.81rem;
  color: #1e2329;
}
.farm-middle-btn {
  border-radius: 16px;
  padding: 0.4rem 1.8rem;
  border: none;
  font-size: 1rem;
  width: 120px;
}
.farm-middle-btn.harvest {
  color: #000000;
}
.farm-middle-btn.history {
  background: #cb9b27;
  color: #fff;
}
.afflicate-box {
  background: #ffffff;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.18);
  border-radius: 16px;
  padding: 1rem;
  /*margin-bottom: 2.5rem;*/
}
.afflicate-head {
  font-weight: 600;
  font-size: 1.93rem;
  color: #1e2329;
}
.afflicate-subhead {
  font-size: 0.93rem;
  color: #1e2329;
}
.afflicate-rectangle {
  background: #dddddd;
  border-radius: 8px;
  padding: 0.5rem 1rem;
}
.rect-level {
  font-weight: 600;
  white-space: nowrap;
  color: #1e2329;
}
.rect-percent {
  font-weight: 600;
  color: #1e2329;
}
.afflicate-rectangle.aff1 {
  height: 183px;
}
.afflicate-rectangle.aff2 {
  height: 157px;
}
.afflicate-rectangle.aff3 {
  height: 127px;
}
.afflicate-rectangle.aff3 {
  height: 97px;
}
.rect-level.aff1 {
  font-size: 1rem;
}
.rect-level.aff2 {
  font-size: 0.87rem;
}
.rect-level.aff3 {
  font-size: 0.81rem;
}
.rect-percent.aff1 {
  font-size: 1.75rem;
}
.rect-percent.aff2 {
  font-size: 1.5rem;
}
.rect-percent.aff3 {
  font-size: 1rem;
}
.personal-link-box {
  background: #dddddd;
  border-radius: 16px;
  padding: 1rem;
}
.personal-link-head {
  font-size: 0.93rem;
  color: #1e2329;
}
.pers-link {
  font-size: 0.87rem;
  color: #1e2329;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 17px;
  width: 60%;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.pers-copy-btn {
  background: rgb(128 195 165);
  border: none;
  border-radius: 16px;
  height: 100%;
  padding: 1rem 2rem;
}
.person-inner-box {
  background: #ffffff;
  border-radius: 16px;
  font-size: 1rem;
  color: #000;
}
.afflicate-end-text {
  font-size: 0.93rem;
  color: #1e2329;
  margin-top: 0.7rem;
}
.read-more-box {
  background: #ffffff;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.18);
  border-radius: 16px;
  padding: 1.5rem 1rem;
  margin: 2rem 0rem 2.5rem 0rem;
}
.read-title {
  font-weight: 600;
  font-size: 1.87rem;
  color: #1e2329;
  margin-bottom: 0.5rem;
}
.read-body {
  font-weight: 500;
  font-size: 1rem;
  color: #1e2329;
}
@media screen and (max-width: 1400px) {
  .farm-item{
    gap: 2rem;
  }
}
@media screen and (max-width: 1200px) {
  .right-stake-section {
    width: 50%;
  }
  .farm-head{
    text-align: center;
  }
  .farm-item{
    justify-content: space-evenly;
  }
  .total-info-box{
    width: 100%;
  }
}
@media screen and (max-width: 1000px) {
  .right-stake-section {
    width: 60%;
  }
  .left-stake-holder {
    width: 100%;
  }

}
@media screen and (max-width: 768px) {
  .right-stake-section {
    width: 70%;
  }
  .stake-arrow-holder {
    padding-left: 10px;
  }
  .farm-inner-item{
    width: 100%;
  }
}
@media screen and (max-width: 575px) {
  .right-stake-section {
    width: 100%;
  }
}
@media screen and (max-width: 400px) {
  /* .person-inner-box {
    flex-wrap: wrap;
  } */
  .pers-link {
    padding: 1rem 0rem;
  }
  /* .pers-copy-btn {
    width: 100%;
  } */
}
/* Footer Section */
.footer-main-section {
  background: rgb(128 195 165);
  width: 100%;
}
.footer-main-top > h2 {
  font-weight: 600;
  font-size: 1.43rem;
  color: #1e2329;
  margin-bottom: 0.5rem;
}
.footer-main-top > p {
  font-size: 1.43rem;
  color: #1e2329;
}
.footer-item-icholder {
  background: #1c1f23;
  border-radius: 15px;
  width: fit-content;
}
.foote-item-icon {
  padding: 1.2rem;
}
.footer-item-title {
  font-weight: 600;
  font-size: 1.18rem;
  color: #1e2329;
  margin-top: 0.5rem;
}
.footer-item-text {
  font-size: 0.87rem;
  color: #1e2329;
  text-align: center;
  width: 90%;
}
.footer-bottom-section {
  background: #dadada;
}
.footer-audit-box {
  background: #ffffff;
  border-radius: 15px;
  width: 40%;
  margin: auto;
}
.footer-bottom-img {
  width: fit-content;
  height: fit-content;
  cursor: pointer;
}
.footer-bottom-img.social{
  height: 35px;
  width: unset;
}
.footer-bottom-img:hover{
  transform: scale(1.1,1.1);
}
.footer-BNBSTAKE {
  font-weight: 700;
  font-size: 0.62rem;
  color: #1e2329;
  text-align: center;
  margin-top: 0.7rem;
}
.footer-BNBSTAKE > span {
  font-weight: normal;
}
.footer-BNBSTAKE-bottom {
  font-weight: 600;
  font-size: 0.62rem;
  color: #1e2329;
  text-align: center;
  margin-top: 0.5rem;
}
@media screen and (max-width: 1200px) {
  .footer-item-holder {
    gap: 2rem;
  }
  .footer-item-text {
    width: 50%;
  }
}
@media screen and (max-width: 786px) {
  .footer-item-text {
    width: 70%;
  }
  .footer-audit-box {
    width: 100%;
  }
}
@media screen and (max-width: 400px) {
  .footer-item-text {
    width: 90%;
  }
  .footer-main-top > h2 {
    font-size: 1.2rem;
  }
  .footer-main-top > p {
    font-size: 1.2rem;
  }
}

/* History Modal */
.modalContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: rgba(18, 22, 28, 0.6);
  backdrop-filter: blur(41px);
}
.modal-holder {
  width: fit-content;
  align-items: center;
  justify-content: center;
}
.history-modal {
  width: 500px;
  height: auto;
  background-color: #fff;
  padding: 2rem;
  border-radius: 20px;
  z-index: 100;
  background-color: rgba(255, 255, 255);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
}
.modal_header {
  position: relative;
}
.modal_header-title {
  font-weight: 600;
  font-size: 1.25rem;
  color: #1e2329;
  text-align: left;
}

.modal_content {
  padding-top: 2rem;
}
.modal_footer button {
  padding: 0.5rem;
  border-radius: 8px;
}
.modal-close {
  background-color: transparent;
  font-weight: 600;
}
.modal-close:hover {
  color: rgba(54, 67, 72, 0.8);
}
.modal_footer .submit {
  margin-right: 1rem;
  color: #fff;
}
.modal_footer .submit:hover {
  opacity: 0.8;
  color: #fff;
}

.modal-left {
  background: linear-gradient(
    179.44deg,
    rgba(0, 0, 0, 0.2) -26.48%,
    rgba(255, 255, 255, 0) 116.3%
  );
  border-radius: 22px;
}

.modal-left-box > h1 {
  font-size: 1.06rem;
  font-weight: bold;
  color: #ffffff;
}
.modal-left-box > img {
  width: fit-content;
  height: fit-content;
}
.modal-left-box > p {
  font-weight: 600;
  font-size: 1.25rem;
  color: rgb(128 195 165);
  background: rgba(18, 21, 27, 0.6);
  border-radius: 23px;
  padding: 0.1rem 0.5rem;
}
.history-head-title {
  font-weight: bold;
  font-size: 0.81rem;
  color: #1e2329;
  width: 100px;
  text-align: center;
}
.historyItem {
  background: #d6d6d6;
  border-radius: 8px;
  padding: 0.7rem 0rem;
}
.historyItem > p {
  width: 100px;
  text-align: center;
  font-size: 0.81rem;
}
.history-item-holder {
  height: 184px;
  overflow-y: scroll;
}
.receipt-btn {
  background-color: rgb(128 195 165);
  padding: 0.3rem 1rem;
  color: #fff;
  border-radius: 10px;
}
.history-head {
  margin-right: 20px;
}
.min-text {
  color: #fff;
  font-size: 0.8rem;
}
.logo {
  height: 58px;
  background-repeat: no-repeat;
}
@media screen and (max-width: 695px) {
  .modal-left{
    flex-direction: row !important;
    width: 90%;
    justify-content: space-evenly !important;
  }
  .modal-left-box{
    text-align: center;
    justify-content: space-between !important;
  }
  .history-modal{
    width: 90%;
  }
  .modal-left-box > h1{
    white-space: nowrap;
  }
  
}
@media screen and (max-width: 400px) {
  .modal-left-box > h1{
    font-size: 0.9rem;
  }
  .modal-left-box > p{
    font-size: 1rem;
    white-space: nowrap;
  }
  .historyItem > p{
    width: 75px;
  }
  .historyItem{
    justify-content: center !important  ;
  }
  .history-head-title{
    width: 80px;
  }
  .history-head{
    margin-right: unset;
  }
}
@media screen and (max-width: 319px) {
  .container {
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .container::-webkit-scrollbar {
    display: none;
  }
}
.mainsection-content
{
  padding: 50px 45px;
}

.mainsection-content h1
{
  font-size: 5rem;
}
.header-holder
{
  border-bottom: 2px solid #dee2e6 !important;
  padding: 48px 0;
}

@media (min-width: 992px) and (max-width: 1200px)
{
  .mainsection-content h1 {
    font-size: 45px;
    font-weight: 400;
    line-height: 62px;
}
}
@media (min-width: 0px) and (max-width: 991px)
{
  .mainsection-content h1 {
    font-size: 36px;
    font-weight: 400;
    line-height: 50px;
}
}

.logo2
{
  width: 100%;
}
.align-center
{
  align-self: center;
}

.header-holder
{
  display: flex !important;
}
@media (min-width: 0px) and (max-width: 360px)
{
  .header-holder
  {
    display: block !important
  }
  .connect-button {
    margin: 12px 0 0 0;
}
}


.imageborder
{
  border-radius: 16px;
}