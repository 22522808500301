div.web3modal-modal-card {
  background-color: transparent;
  box-shadow: 0 0 60px #00000045;
  position: relative;
  overflow: initial;
}
div.web3modal-modal-card::before {
  content: "Connect to a wallet";
  position: absolute;
  color: #fff;
  z-index: 100000;
  display: flex;
  font-size: 1.6rem;
  height: 2rem;
  top: -80px;
  left: 0;
  right: 0;
  padding: 1rem;
  justify-content: center;
  align-items: center;
}
div.web3modal-modal-card .web3modal-provider-wrapper {
  padding: 4px;
  border: none;
}
div.web3modal-modal-card .web3modal-provider-wrapper:not(:first-child) {
  padding-top: 0px;
}
div.web3modal-modal-card
  .web3modal-provider-wrapper:hover
  .web3modal-provider-container {
  background-color: #ffffff17;
}
div.web3modal-modal-card
  .web3modal-provider-wrapper:hover
  .web3modal-provider-icon
  img {
  transform: scale(1.2);
}
div.web3modal-modal-card
  .web3modal-provider-wrapper:hover
  .web3modal-provider-name {
  color: #fff;
}
div.web3modal-modal-card .web3modal-provider-container {
  flex-direction: row;
  background-color: rgb(128 195 165);
  border-radius: 12px;
  padding: 10px 12px;
  align-items: center;
  justify-content: center;
  border: none;
  transition: 0.3s;
  transition: all 0.3 ease-in;
  box-shadow: 0 0 100px 0px #0000001c;
  border: 1px solid #00000014;
}
div.web3modal-modal-card .web3modal-provider-icon {
  border-radius: 1rem;
  background: #0302055e;
  padding: 0.6rem;
  width: 60px;
  height: 60px;
  transition: 0.3s;
}
@media screen and (max-width: 768px) {
  div.web3modal-modal-card .web3modal-provider-icon {
    width: 8.5vw;
    height: 8.5vw;
    padding: 0;
  }
}
div.web3modal-modal-card .web3modal-provider-icon img {
  min-width: 2.5rem;
  width: 100%;
  height: 100%;
  transition: 0.3s;
}
div.web3modal-modal-card .web3modal-provider-name {
  margin-top: 0em;
  margin-left: 0.5em;
  text-align: left;
  transition: 0.3s;
  color: #fff;
}
div.web3modal-modal-card .web3modal-provider-description {
  display: none;
}
div.web3modal-modal-lightbox {
  background-color: #000000c9;
  backdrop-filter: blur(6px);
  background-color: #0000008f;
  z-index: 1000000;
}
/*# sourceMappingURL=Connection.css.map */
